"use client";

import {
  Navbar as NextUINavbar,
  NavbarContent,
  NavbarBrand,
  NavbarMenuToggle,
  NavbarMenu,
  NavbarMenuItem,
} from "@nextui-org/navbar";
import { ReactNode, useState } from "react";
import { LogoWithText } from "@/components/icons";
import NextLink from "next/link";
import { Link } from "@nextui-org/link";
import Image from "next/image";

export const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuItems = [
    { href: "/company", label: "会社概要" },
    { href: "/axis", label: "私たちの軸" },
    { href: "/news", label: "ニュース" },
    {
      href: "https://copalette-app.notion.site/CoPalette-be90d79ce2214d5d8d1f4a9829826c3d?pvs=4",
      label: "採用情報",
      isExternal: true,
    },
    { href: "/contact", label: "お問い合わせ" },
  ];

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <NextUINavbar
      maxWidth="xl"
      position="sticky"
      isMenuOpen={isMenuOpen}
      onMenuOpenChange={setIsMenuOpen}
    >
      <NavbarContent className="sm:hidden" justify="start">
        <NavbarMenuToggle
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
        />
      </NavbarContent>

      <NavbarContent className="sm:hidden pr-3" justify="center">
        <NavbarBrand>
          <NextLink href="/">
            <Image
              src="/image/logo.png"
              alt="logo black"
              width={48}
              height={48}
            />
          </NextLink>
        </NavbarBrand>
      </NavbarContent>

      <NavbarContent className="hidden sm:flex" justify="start">
        <NavbarBrand as="li">
          <NextLink className="flex items-center justify-start gap-1" href="/">
            <LogoWithText />
          </NextLink>
        </NavbarBrand>
      </NavbarContent>

      <NavbarContent className="hidden sm:flex" justify="end">
        <ul className="gap-5 lg:gap-10 flex">
          {menuItems.map((item, index) => (
            <Link
              key={index}
              href={item.href}
              isExternal={item.isExternal}
              showAnchorIcon={item.isExternal}
              anchorIcon={
                item.isExternal ? (
                  <i className="ri-external-link-line text-black pl-1" />
                ) : undefined
              }
            >
              <button className="font-bold text-black text-sm lg:text-md text-nowrap">
                {item.label}
              </button>
            </Link>
          ))}
        </ul>
      </NavbarContent>

      <NavbarMenu>
        {menuItems.map((item, index) => (
          <NavbarMenuItem key={`${item.label}-${index}`}>
            <Link
              href={item.href}
              size="lg"
              isExternal={item.isExternal}
              showAnchorIcon={item.isExternal}
              anchorIcon={
                item.isExternal ? (
                  <i className="ri-external-link-line text-black pl-1" />
                ) : undefined
              }
              onClick={handleLinkClick}
            >
              {item.label}
            </Link>
          </NavbarMenuItem>
        ))}
      </NavbarMenu>
    </NextUINavbar>
  );
};
